import Carousel from '@brainhubeu/react-carousel';
import React, { useState } from 'react';
import styled from 'styled-components';

import '@brainhubeu/react-carousel/lib/style.css';

import { Code } from '@components/Typography';

import { laptop } from '@constants';

import trackRegistrationsSrc from '@images/features/take-track-registrations.png';
import reviewApplicantsSrc from '@images/features/review-applicants.png';
import collaborateTeamSrc from '@images/features/collaborate-with-team.png';
import senseLogisticsSrc from '@images/features/make-sense-logistics.png';

import playSrc from '+static/play.svg';

const StyledSlider = styled.div`
  display: none;

  ${laptop} {
    display: block;
  }
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Pill = styled(Flex)`
  padding: 24px 48px;
  background-color: #3936ab;
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 400px;
  background-image: url('/elements/yellow-fin.svg'), url('/elements/yellow-fin.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: 92% -44px, -20px 40px;
`;

const Button = styled.button`
  background-color: #3770ff;
  border-radius: 50%;
  border: none;
  color: #ffffff;
  height: 40px;
  width: 40px;
  cursor: pointer;
  outline: none;
`;

const Slider = ({
  labels = [
    'Track and accept registrations',
    'Review applicants with ease',
    'Collaborate with your team',
    'Make sense of your logistics',
  ],
}) => {
  const [active, setActive] = useState(0);
  const activeLabelIndex = Math.abs(active % labels.length);

  return (
    <StyledSlider>
      <Flex css="z-index: 2">
        <div css="margin-right: 12px">
          <Button onClick={() => setActive(active - 1)}>
            <div css="margin-right: 2px; margin-top: 1px; transform: rotate(180deg)">
              <img src={playSrc} alt="previous" />
            </div>
          </Button>
        </div>

        <Pill>
          <Code color="white">{labels[activeLabelIndex]}</Code>
        </Pill>

        <div css="margin-left: 12px">
          <Button onClick={() => setActive(active + 1)}>
            <div css="margin-left: 2px; margin-top: 3px">
              <img src={playSrc} alt="next" />
            </div>
          </Button>
        </div>
      </Flex>

      <div css="width: 100%">
        <div css="margin-top: -40px">
          <Carousel
            value={active}
            onChange={() => setActive(active + 1)}
            itemWidth={820}
            slidesPerPage={2}
            autoPlay={4000}
            animationSpeed={1000}
            draggable={false}
            centered
            infinite
          >
            <img src={trackRegistrationsSrc} alt="Track and accept registrations" />
            <img src={reviewApplicantsSrc} alt="Review applicants with ease" />
            <img src={collaborateTeamSrc} alt="Collaborate with your team" />
            <img src={senseLogisticsSrc} alt="Make sense of your logistics" />
          </Carousel>
        </div>
      </div>
    </StyledSlider>
  );
};

export default Slider;
