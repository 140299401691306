import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';

import { Code } from '@components/Typography';
import Link from '@components/ExternalLink';
import theme from '@components/Theme';

import { breakpoint } from '@constants';
import favoriteHackers from '@constants/favoriteHackers';

const getProfileUrl = (username) => `https://devfolio.co/@${username}`;

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Favorites = styled.div`
  ${flexCenter};
  flex-direction: column;
  margin: 2em 0;

  ${(props) =>
    props.projects &&
    css`
      padding: 40px;
      background-color: #f7f7f8;
    `};

  @media screen and (max-width: ${breakpoint.max.md}) {
    display: none;
  }
`;

export const List = styled.ul`
  ${flexCenter};
  margin: 24px 0;
  padding: 0;
  list-style-type: none;
`;

const Hacker = styled.li`
  margin: 12px;
  cursor: pointer;
`;

const Avatar = styled(Img)`
  width: 108px;
  height: 108px;
  max-width: 108px;
  max-height: 108px;
  min-width: 108px;
  min-height: 108px;
  border-radius: 50%;
  border: 6px solid ${(props) => theme.color[props.borderColor]};
  transition: box-shadow 200ms ease-out;

  &:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.17);
  }
`;

const FavoriteDevfolios = () => {
  const [currentActive, setActive] = React.useState(0);
  const currentHacker = favoriteHackers[currentActive];

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { sourceInstanceName: { eq: "hackers" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(quality: 100, maxWidth: 108) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const getImage = (hacker) => {
          const imageEdge = data.allFile.edges.find(
            ({ node }) => node.relativePath === hacker.image
          );
          const image = imageEdge.node;

          return image.childImageSharp.fluid;
        };

        return (
          <Favorites>
            <Code color="lightestGrey">
              Some of our favorite Devfolios |{' '}
              <Link href={getProfileUrl(currentHacker.username)}>{currentHacker.name}</Link>
            </Code>

            <List>
              {favoriteHackers.map((hacker, index) => {
                return (
                  <Hacker onMouseEnter={() => setActive(index)} key={hacker.username}>
                    <Link href={getProfileUrl(hacker.username)}>
                      <Avatar
                        fluid={getImage(hacker)}
                        borderColor={hacker.borderColor}
                        alt={`${hacker.name}'s avatar`}
                      />
                    </Link>
                  </Hacker>
                );
              })}
            </List>
          </Favorites>
        );
      }}
    />
  );
};

export default React.memo(FavoriteDevfolios);
