import Carousel from '@brainhubeu/react-carousel';
import React, { memo } from 'react';
import styled from 'styled-components';

import '@brainhubeu/react-carousel/lib/style.css';

import { H1, H2, P } from '@components/Typography';
import { breakpoint, phone, tablet } from '@constants';
import theme from '@components/Theme';
import { Section, Clickable, Shadow } from '@components/_global';
import Grid, { Item } from '@components/Grid';

import blueArcSrc from '+static/elements/blue-arc.svg';
import blueFinSrc from '+static/elements/blue-fin.svg';
import jawDropSrc from '+static/characters/teal-jaw-drop.svg';

import community1Src from '@images/misc/community-1.png';
import community2Src from '@images/misc/community-2.png';
import community3Src from '@images/misc/community-3.png';
import community4Src from '@images/misc/community-4.png';
import community5Src from '@images/misc/community-5.png';
import community6Src from '@images/misc/community-6.png';

const ELEMENTS = [
  {
    src: jawDropSrc,
    css: {
      right: '8rem',
      top: '-5rem',
    },
    'data-art': 'jaw-drop',
  },
  {
    src: blueFinSrc,
    css: {
      right: '28%',
      bottom: '-2rem',
    },
  },
  {
    src: blueArcSrc,
    css: {
      left: '6rem',
      top: '-8.2rem',
      zIndex: '1',
      transform: 'rotate(270deg) scale(0.8)',
    },
    'data-art': 'blue-arc',
  },
];

// ____________________________________________________________________
// Styles

const Element = styled.div`
  position: absolute;

  @media (max-width: ${breakpoint.max.md}) {
    &[data-art='blue-arc'] {
      left: 2rem;
      transform: scale(0.6);
    }

    &[data-art='jaw-drop'] {
      right: -4rem;
      transform: scale(0.8);
      top: -14rem;
    }
  }
`;

const StatItem = styled.li`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 36px;
  border-radius: 50px;
  position: relative;
  z-index: 1;
  height: 76px;
  background-color: ${(props) => props.bgColor};
  width: ${(props) => props.width};
  justify-content: start;

  &:hover {
    cursor: default;
    background-color: ${(props) => props.bgColor};
  }
`;

const StatsList = styled.ul`
  max-width: 472px;
  padding: 0;
  margin: 40px 0 0 -6em;

  > li {
    margin-bottom: 20px;
    list-style: none;
  }

  ${phone} {
    margin: 40px 0 4em 0;
    max-width: 100%;

    > li {
      width: 100%;
    }
  }

  ${tablet} {
    margin: 40px 0 4em 0;
    max-width: 472px;
  }
`;

const SliderBox = styled.div`
  position: relative;
  margin-top: -8rem;

  img {
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
  }
`;

const StatH2 = styled(H2)`
  @media screen and (max-width: 400px) {
    font-size: ${theme.sm.fontSize.h3};
  }
`;

const StatP = styled(P)`
  @media screen and (max-width: 345px) {
    font-size: 18px;
  }
`;

const Slider = () => {
  const [active, setActive] = React.useState(0);

  return (
    <SliderBox>
      <Shadow>
        <Carousel
          onChange={() => setActive(active + 1)}
          value={active}
          autoPlay={3000}
          animationSpeed={800}
          draggable={false}
          infinite
        >
          <img src={community1Src} alt="1" />
          <img src={community2Src} alt="2" />
          <img src={community3Src} alt="3" />
          <img src={community4Src} alt="4" />
          <img src={community5Src} alt="5" />
          <img src={community6Src} alt="6" />
        </Carousel>
      </Shadow>

      <Clickable height="100%" width="100%">
        <Clickable.Area
          arrow="left"
          top="0"
          height="100%"
          left="0"
          width="50%"
          onClick={() => setActive(active - 1)}
        />
        <Clickable.Area
          arrow="right"
          top="0"
          height="100%"
          left="50%"
          width="50%"
          onClick={() => setActive(active + 1)}
        />
      </Clickable>
    </SliderBox>
  );
};

const Stat = ({ name, value, bgColor, width }) => {
  return (
    <StatItem bgColor={bgColor} width={width}>
      <StatH2 color="white">{value}</StatH2>&nbsp;
      <StatP size="large" color="white">
        {name}
      </StatP>
    </StatItem>
  );
};

const Stats = () => {
  return (
    <StatsList>
      <Stat bgColor="#58d1bd" name="builders" value="550,000+" width="100%" />
      <Stat bgColor="#00e97b" name="projects" value="45,000+" width="85%" />
      <Stat bgColor="#81a2ef" name="hackathons" value="1,000+" width="80%" />
    </StatsList>
  );
};

const Community = () => (
  <Section
    id="community"
    bgColor="white"
    css="position: relative; overflow-y: visibile; overflow-x:clip;"
    grid
  >
    {ELEMENTS.map((element, index) => (
      <Element key={index} css={element.css} data-art={element['data-art']}>
        <img src={element.src} alt="" />
      </Element>
    ))}

    <Grid defaultOuter>
      <Item
        column={{
          sm: { start: 2, span: 7 },
          md: { start: 1, span: 10 },
          lg: { start: 1, span: 6 },
        }}
      >
        <Slider />
      </Item>

      <Item
        column={{
          sm: { start: 2, span: 7 },
          md: { start: 1, span: 10 },
          lg: { start: 7, span: 5 },
        }}
      >
        <H1>We drop jaws!</H1>

        <div css="margin-top: 16px">
          <P>
            In January 2019, we powered our first community hackathon. Since then we've grown to
          </P>
        </div>

        <Stats />
      </Item>
    </Grid>
  </Section>
);

export default memo(Community);
