import axios from 'axios';
import React from 'react';
import styled from 'styled-components';

import { Container, Underline, Section } from '@components/_global';
import { A, H2 } from '@components/Typography';
import Hackathon from '@components/Hackathon';

import kissSrc from '+static/elements/kiss.svg';
import tealStarSrc from '+static/elements/teal-star.svg';

import { phone, tablet } from '@constants';

const ELEMENTS = [
  {
    src: kissSrc,
    css: {
      position: 'absolute',
      top: '-150px',
      left: '90px',
    },
  },
  {
    src: tealStarSrc,
    css: {
      position: 'absolute',
      right: '8rem',
      top: '10rem',
    },
  },
];

const HACKATHONS = [
  {
    slug: 'inout2019',
    primaryColor: '#6bc8b0',
    name: 'InOut 6.0',
    startsAt: '2019-10-19T02:30:00.000Z',
    city: 'Bengaluru',
    country: 'India',
    website: 'https://hackinout.co',
  },
  {
    slug: 'ethindia2019',
    name: 'ETHIndia 2.0',
    startsAt: '2019-08-02T02:30:00.000Z',
    city: 'Bengaluru',
    country: 'India',
    primaryColor: '#003b50',
    website: 'https://ethindia.co',
  },
  {
    slug: 'wmn',
    name: 'wmn;',
    startsAt: '2019-06-01T11:30:00.000Z',
    city: 'Bengaluru',
    country: 'India',
    primaryColor: '#f6f6a4',
    website: 'https://wmn.community',
  },
];

const Grid = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    width: calc(33.3333% - 1.3334em);
    padding: 0.6667em;

    ${tablet} {
      width: calc(50% - 1.3334em);
    }

    ${phone} {
      width: 100%;
      padding: 0.6667em 0;
    }
  }
`;

const AllHackathons = (props) => {
  const [hackathons, setHackathons] = React.useState(HACKATHONS);

  /**
   * This effect fetches the hackathons who have registrations open.
   * And sets the hackathon state.
   */
  React.useEffect(() => {
    const liveHackathonsUrl = `${process.env.GATSBY_API_ROOT}hackathons?filter=application_open&page=1&limit=3`;

    axios
      .get(liveHackathonsUrl)
      .then(({ data }) => {
        const { result = [] } = data;

        const hackathons = result.map((hackathon) => ({
          uuid: hackathon.uuid,
          name: hackathon.name,
          slug: hackathon.slug,
          startsAt: hackathon.starts_at,
          country: hackathon.country,
          city: hackathon.city,
          isOnline: hackathon.is_online || hackathon.hackathon_setting.review,
          primaryColor: hackathon.hackathon_setting.primary_color,
          regStartsAt: hackathon.hackathon_setting.reg_starts_at,
          regEndsAt: hackathon.hackathon_setting.reg_ends_at,
        }));

        setHackathons(hackathons);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  
  return (
    <Section id="hackathons" css="position: relative">
      {ELEMENTS.map((element, index) => (
        <div key={index} css={element.css}>
          <img src={element.src} alt="" />
        </div>
      ))}

      <Container css="z-index: 1; position: relative">
        <div css="text-align: center; margin-bottom: 4rem">
          <H2>
            Happening {``}
            <Underline color="lightTeal">now</Underline>
          </H2>
        </div>

        <Grid>
          {hackathons.map((hackathon) => (
            <li>
              <Hackathon key={hackathon.slug} {...hackathon} />
            </li>
          ))}
        </Grid>

        <div css="margin-top: 3rem; text-align: center">
          <A appearance="link" href="https://devfolio.co/hackathons">
            See all
          </A>
        </div>
      </Container>
    </Section>
  );
};

export default React.memo(AllHackathons);
