import React from 'react';
import styled, { css } from 'styled-components';

const TextField = styled.input`
  box-sizing: border-box;
  background: ${(props) => props.theme.color.white};
  border-radius: 20px;
  border: 3px solid transparent;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.color.grey};
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  outline: none;
  padding: 18.5px 24px;

  ${(props) =>
    props.stretch &&
    css`
      width: 100%;
    `};

  &::placeholder {
    color: ${(props) => props.theme.color.lightestGrey};
  }

  &:focus {
    border: 3px solid ${(props) => props.theme.color.blue};
  }
`;

export default (props) => {
  return <TextField type="text" {...props}></TextField>;
};
