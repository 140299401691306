export default [
  {
    name: 'Darth VDR',
    slug: 'darth-vdr-virtual-data-room',
    bgColor: 'sky',
  },
  {
    name: 'InstaDApp',
    slug: 'instadapp-using-makerdao-kyber-network-and-embark-framework',
    bgColor: 'greenCyan',
  },
  {
    name: 'Alfred',
    slug: 'alfred',
    bgColor: 'lightTeal',
  },
  {
    name: 'Workout Right',
    slug: 'workoutright',
    bgColor: 'teal',
  },
];
