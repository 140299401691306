import React from 'react';
import styled, { css } from 'styled-components';

import { phone, tablet, laptop } from '@constants';
import theme from '@components/Theme';

const Box = styled.div`
  display: grid;
  background-color: ${(props) => theme.color[props.bgColor] || 'transparent'};
  justify-content: center;

  // Default inner grid
  ${(props) =>
    props.defaultInner &&
    css`
      ${phone} {
        grid-gap: 50px 10px;
        grid-template-columns: repeat(9, minmax(20px, 1fr));
      }

      ${tablet} {
        grid-gap: 64px 16px;
        grid-template-columns: repeat(10, 48px);
      }

      ${laptop} {
        grid-gap: 100px 20px;
        grid-template-columns: repeat(14, 50px);
      }
    `}

    // Default outer grid
    ${(props) =>
      props.defaultOuter &&
      css`
        ${phone} {
          grid-gap: 50px 10px;
          grid-template-columns: repeat(9, minmax(20px, 1fr));
        }

        ${tablet} {
          grid-gap: 64px 16px;
          grid-template-columns: repeat(10, 48px);
        }

        ${laptop} {
          grid-gap: 100px 20px;
          grid-template-columns: repeat(12, 82px);
        }
      `}
`;

const Item = styled.div`
  ${phone} {
    ${({ column }) =>
      column.sm &&
      css`
        grid-column: ${column.sm.start} / span ${column.sm.span};
      `};
  }

  ${tablet} {
    ${({ column }) =>
      column.md &&
      css`
        grid-column: ${column.md.start} / span ${column.md.span};
      `};
  }

  ${laptop} {
    ${({ column }) =>
      column.lg &&
      css`
        grid-column: ${column.lg.start} / span ${column.lg.span};
      `};
  }
`;

const Grid = (props) => {
  const { children, ...rest } = props;
  return <Box {...rest}>{children}</Box>;
};

export default Grid;
export { Item };
