import React, { useContext } from 'react';

// Not removing Banner code since it's handy and we might use it later
// import Banner from '@components/Banner';
import Layout from '@components/Layout';

import AllHackathons from '@sections/AllHackathons';
import Testimonials from '@sections/Testimonials';
import Community from '@sections/Community';
import Features from '@sections/Features';
import Footer from '@sections/Footer';
import Header from '@sections/Header';
import Hero from '@sections/Hero';
import Connect from '@sections/Connect';
import ForCommunity from '@sections/ForCommunity';
import ForCompany from '@sections/ForCompany';

import IsLoggedInContext from '@contexts/IsLoggedIn';

export const Home = () => {
  const isLoggedIn = useContext(IsLoggedInContext);

  return (
    <Layout white>
      {/* <Banner /> */}
      <Header isLoggedIn={isLoggedIn} />

      <Hero />
      <Community />
      <AllHackathons />
      <Testimonials />
      <Features />
      <Connect />
      <ForCommunity />
      <ForCompany />

      <Footer />
    </Layout>
  );
};

export default () => {
  return <Home />;
};
