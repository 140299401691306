import React from 'react';
import styled from 'styled-components';

import { breakpoint } from '@constants';
import { H2, H3, P } from '@components/Typography';
import { Section, Container, Button } from '@components/_global';

import charGreenSrc from '+static/characters/green.svg';
import charYellowSrc from '+static/characters/yellow.svg';
import CopyMailTo from 'react-copy-mailto';

const Flex = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 3.2em auto;
  max-width: 820px;
  text-align: center;

  @media (max-width: ${breakpoint.max.sm}) {
    margin: 0 auto;

    img {
      display: none;
    }
  }
`;

const SectionPatterned = styled(Section)`
  background-color: #3770ff;
  background-image: url('/pattern.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const ForCompany = () => {
  return (
    <SectionPatterned id="for-company">
      <Container>
        <Flex>
          <H3 color="white">
            Devfolio for <span css="font-weight: 900">companies</span>
          </H3>

          <H2 color="white" css="margin: 32px 0">
            Your engineering team and developer products need a brand and a fan base. That’s where
            we come in.
          </H2>

          <P color="white">
            A known brand makes hiring and adoption of APIs easier and wide. We have proof!
          </P>

          <div css="margin-top: 36px; display: flex; justify-content: center">
            <CopyMailTo
              tooltipStyles={{ top: '-64px', height: 'fit-content' }}
              email="sales@devfolio.co"
            >
              <Button as="span">Learn more</Button>
            </CopyMailTo>
          </div>

          <img src={charGreenSrc} alt="" css="position: absolute; right: -72px; top: -40px" />
          <img src={charYellowSrc} alt="" css="position: absolute; bottom: 24px; left: -112px" />
        </Flex>
      </Container>
    </SectionPatterned>
  );
};

export default React.memo(ForCompany);
