import React from 'react';
import styled from 'styled-components';

import { Favorites, List, flexCenter } from '@components/FavoriteDevfolios';
import { P, Code } from '@components/Typography';
import Link from '@components/ExternalLink';

import favoriteProjects from '@constants/favoriteProjects';

const getProjectUrl = (slug) => `https://devfolio.co/submissions/${slug}`;

const Card = styled.li`
  ${flexCenter};
  margin: 12px;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 32px;
  transition: transform 200ms ease-out, box-shadow 200ms ease-out;
`;

const Project = styled.div`
  display: flex;
  flex-direction: column;

  &:hover ${Card} {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px;
    transform: translateY(-2px);
  }
`;

const Initial = styled.div`
  ${flexCenter};
  width: 126px;
  height: 126px;
  max-width: 126px;
  max-height: 126px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color[props.bgColor]};
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 36.8px;
  line-height: 100%;
  color: ${(props) => props.theme.color.white};
`;

const FavoriteProjects = () => {
  return (
    <Favorites projects>
      <Code color="lightestGrey">A few of our favorite projects on Devfolio</Code>

      <List>
        {favoriteProjects.map((project) => {
          return (
            <Link key={project.slug} href={getProjectUrl(project.slug)}>
              <Project>
                <Card>
                  <Initial bgColor={project.bgColor}>{project.name.charAt()}</Initial>
                </Card>
                <div css="margin-top: 12px">
                  <P color="lightGrey" css="font-weight: 700; text-align: center">
                    {project.name}
                  </P>
                </div>
              </Project>
            </Link>
          );
        })}
      </List>
    </Favorites>
  );
};

export default React.memo(FavoriteProjects);
