import React from 'react';
import styled from 'styled-components';

import { H2, P } from '@components/Typography';
import { Button } from '@components/_global';

import { breakpoint } from '@constants';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto 0;
  z-index: 2;

  @media screen and (max-width: ${breakpoint.max.md}) {
    margin-top: 24px;

    > div {
      text-align: center;
    }

    button {
      margin: 0 auto;
    }
  }
`;

const Icon = styled.div`
  margin-bottom: 1em;

  > img:last-child {
    display: none;
    margin: 0 auto;
  }

  @media screen and (max-width: ${breakpoint.max.md}) {
    > img:first-child {
      display: none;
    }

    > img:last-child {
      display: block;
    }
  }
`;

const Title = styled.div`
  margin: 1em 0 2em 0;
`;

const FeaturesCard = (props) => {
  const { icon, digit, heading, text, callToAction, action } = props;

  return (
    <Box>
      <Icon>
        {icon}
        <img width="313px" height="66px" src={digit} alt="" />
      </Icon>

      <div>
        <H2>{heading}</H2>

        <Title>
          <P>{text}</P>
        </Title>

        <Button onClick={action}>{callToAction}</Button>
      </div>
    </Box>
  );
};

export default React.memo(FeaturesCard);
