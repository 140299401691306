import React from 'react';
import styled from 'styled-components';
import { TypeAnimation } from 'react-type-animation';

import { H1, P } from '@components/Typography';
import { Section as SectionComponent, Underline } from '@components/_global';
import CookieToast from '@components/CookieToast';
import Grid, { Item } from '@components/Grid';
import HeroElements from '@components/HeroElements';
import Icon from '@components/Icon';
import TextField from '@components/TextField';

import { breakpoint, phone, tablet, laptop } from '@constants';
import { routeToSignUp } from '@helpers';

import joinSrc from '+static/join-us.svg';

const Section = styled(SectionComponent)`
  ${phone} {
    padding-top: 0 !important;
    padding-bottom: 12rem !important;
  }

  ${tablet} {
    padding-top: 2rem !important;
    padding-bottom: 12rem !important;
  }

  ${laptop} {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.blue};
  border-radius: 50%;
  border: none;
  color: #fff;
  cursor: pointer;
  height: 60px;
  outline: none;
  width: 60px;

  > div,
  svg {
    height: 32px;
    width: 32px;
  }

  :hover {
    background-color: ${(props) => props.theme.color.navy};
  }
`;

const Tagline = styled.div`
  margin: 4rem 0;
  text-align: center;

  ${phone} {
    text-align: left;
    margin: 1.7777rem 0 3rem;
  }
`;

const Form = styled.form`
  max-width: 520px;
  display: flex;
  flex-direction: column;
  transform: translateX(38px);
  width: 100%;

  @media (max-width: ${breakpoint.max.sm}) {
    transform: translateX(0);

    > div:first-child {
      margin-left: 0;
    }

    > input {
      width: 100%;
    }
  }
`;

const Inputs = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoint.max.sm}) {
    flex-direction: column;
  }
`;

const CatchStar = styled.span`
  @media (max-width: ${breakpoint.max.sm}) {
    display: none;
  }
`;

const CatchMaybe = styled.div`
  margin-top: 16px;

  @media (max-width: ${breakpoint.max.sm}) {
    display: none;
  }
`;

const RegisterForm = () => {
  const [email, setEmail] = React.useState('');

  const onChange = React.useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      routeToSignUp(email);
    },
    [email]
  );

  return (
    <Form onSubmit={onSubmit}>
      <div css="margin-left: 24px">
        <label htmlFor="email">
          <img src={joinSrc} css="height: 16px" alt="" />
        </label>
      </div>

      <Inputs>
        <TextField
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={onChange}
          placeholder="Your email"
          stretch
          required
        />

        <div css="margin: 16px">
          <Button type="submit">
            <Icon name="arrow-right" color="blue" />
          </Button>
        </div>
      </Inputs>
    </Form>
  );
};

const Hero = () => {
  return (
    <Section id="hero" css="position: relative; overflow-y: visibile; overflow-x:clip;" grid>
      <Grid defaultInner>
        <Item
          column={{
            sm: { start: 2, span: 7 },
            md: { start: 1, span: 10 },
            lg: { start: 1, span: 14 },
          }}
        >
          <Flex>
            <Tagline css="min-height: 300px">
              <H1 color="grey" >
                <span css="font-weight: 400">Redefining</span> economic opportunities{' '}
                <span css="font-weight: 400">for builders with</span><br></br>


                <Underline>
                  <TypeAnimation
                    sequence={[
                      500,
                      'hackathons',
                      1500,
                      'fellowships',
                      1500,
                      'grants',
                      1500,
                    ]}
                    wrapper="span"
                    cursor={false}
                    repeat={Infinity}
                  />
                </Underline>
              </H1>
            </Tagline>

            <RegisterForm />

            <HeroElements />
          </Flex>
        </Item>
      </Grid>

      <CookieToast />
    </Section>
  );
};

export default React.memo(Hero);
