import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { breakpoint } from '@constants';

import blueFinSrc from '+static/elements/blue-fin.svg';
import blueBallSrc from '+static/elements/blue-ball.svg';
import beigeStarSrc from '+static/elements/beige-star.svg';
import tealOrbSrc from '+static/elements/teal-orb.svg';
import blueArcSmallSrc from '+static/elements/blue-arc-small.svg';
import coffeeSmileSrc from '+static/characters/coffee-smile.svg';
import beigeLipSrc from '+static/characters/beige-lip.svg';
import yellowSmileSrc from '+static/characters/yellow-slight-smile.svg';
import tealWhistleSrc from '+static/characters/teal-whistle.svg';
import greenWhistleSrc from '+static/characters/green-whistle.svg';
import beigeSmileSrc from '+static/characters/beige-smile.svg';

const ELEMENTS = [
  {
    animate: true,
    src: coffeeSmileSrc,
    position: {
      top: '-56px',
      left: '50%',
    },
  },
  {
    animate: true,
    src: beigeLipSrc,
    position: {
      left: '100px',
      top: '-88px',
    },
  },
  {
    animate: true,
    src: yellowSmileSrc,
    position: {
      top: '1rem',
      right: '-3rem',
      zIndex: 2,
    },
  },
  {
    animate: true,
    src: tealWhistleSrc,
    position: {
      top: '-0.5rem',
      right: '-5rem',
    },
  },
  {
    animate: true,
    src: greenWhistleSrc,
    position: {
      left: '-4rem',
      top: '96px',
    },
  },
  {
    animate: true,
    src: beigeSmileSrc,
    position: {
      bottom: '32px',
      left: '80px',
    },
  },
  {
    spin: true,
    src: beigeStarSrc,
    position: {
      bottom: '240px',
      right: '32px',
    },
    'data-art': 'beige-star',
  },
  {
    linearMedium: true,
    src: blueBallSrc,
    position: {
      right: '20%',
      top: '0px',
    },
  },
  {
    linearSmall: true,
    src: blueFinSrc,
    position: {
      left: '132px',
      top: '50%',
    },
    'data-art': 'blue-fin',
  },
  {
    animate: false,
    src: tealOrbSrc,
    position: {
      bottom: '48px',
      left: '56%',
      transform: 'scale(1.2)',
    },
    'data-art': 'teal-orb',
  },
  {
    spinSmall: true,
    src: blueArcSmallSrc,
    position: {
      right: '26%',
      bottom: '-4rem',
    },
  },
];

const Elements = styled(motion.div)`
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
`;

const Element = styled(motion.img)`
  position: absolute;

  @media (max-width: ${breakpoint.max.md}) {
    display: none;

    &[data-art='blue-fin'] {
      display: block;
      top: -6rem;
      left: -4rem;
    }

    &[data-art='beige-star'] {
      display: block;
      left: 100%;
    }

    &[data-art='teal-orb'] {
      display: block;
      bottom: 76px;
    }
  }
`;

const container = {
  hidden: {
    opacity: 0.8,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      ease: [0.16, 0.8, 0.49, 0.99],
    },
  },
};

const spin = {
  animate: {
    rotate: 360,
  },
  transition: {
    loop: Infinity,
    ease: 'linear',
    duration: 8,
    delay: 0.5,
  },
};

const spinSmall = {
  animate: {
    rotate: 360,
  },
  transition: {
    loop: Infinity,
    ease: 'linear',
    duration: 12,
    delay: 0.4,
  },
};

const linearSmall = {
  animate: {
    y: 20,
  },
  transition: {
    yoyo: Infinity,
    ease: [0.37, 0, 0.63, 1],
    duration: 1,
    delay: 0.5,
  },
};

const linearMedium = {
  animate: {
    y: 50,
  },
  transition: {
    yoyo: Infinity,
    ease: [0.37, 0, 0.63, 1],
    duration: 3,
    delay: 1,
  },
};

const HeroElements = () => {
  return (
    <Elements variants={container} initial="hidden" animate="visible">
      {ELEMENTS.map((element, index) => (
        <Element
          alt=""
          key={index}
          src={element.src}
          css={element.position}
          data-art={element['data-art']}
          // The rotate 360
          {...(element.spin && spin)}
          // Rotate slower
          {...(element.spinSmall && spinSmall)}
          // Small Y translate
          {...(element.linearSmall && linearSmall)}
          // Medium Y translate
          {...(element.linearMedium && linearMedium)}
          // The pop-in animation variant
          {...(element.animate && {
            variants: item,
          })}
        />
      ))}
    </Elements>
  );
};

export default React.memo(HeroElements);
