import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';

import { H2, P } from '@components/Typography';
import { Section, Clickable, Shadow } from '@components/_global';
import Grid, { Item } from '@components/Grid';

import { breakpoint, phone } from '@constants';

import beigeStarSrc from '+static/elements/beige-star.svg';
import blueFinSrc from '+static/elements/blue-fin.svg';
import yellowCharSrc from '+static/characters/yellow-broad-smile.svg';
// import ethIndiaBadgeSrc from '+static/badge-ethindia.svg';

import oneSrc from '+static/testimonials/1.jpg';
import twoSrc from '+static/testimonials/2.jpg';
import threeSrc from '+static/testimonials/3.jpg';

const spin = {
  animate: {
    rotate: -360,
  },
  transition: {
    loop: Infinity,
    ease: 'linear',
    duration: 6,
    delay: 0.5,
  },
};

const spinReverse = {
  animate: {
    rotate: 360,
  },
  transition: {
    loop: Infinity,
    ease: 'linear',
    duration: 24,
    delay: 0.5,
  },
};

const ELEMENTS = [
  {
    spin: true,
    src: beigeStarSrc,
    css: 'top: -32px; right: -64px',
  },
  {
    spinReverse: true,
    src: blueFinSrc,
    css: 'bottom: -64px; left: -32px; height: 120px',
  },
  {
    src: yellowCharSrc,
    css: 'top: 32px; left: -96px; z-index: 1',
  },
  //   {
  //     src: ethIndiaBadgeSrc,
  //     css: 'bottom: -24px; right: -24px; z-index: 1',
  //   },
];

const StyledSection = styled(Section)`
  padding-top: 0 !important;

  ${phone} {
    padding: 0 !important;
  }
`;

const StyledItem = styled(Item)`
  align-self: center;

  @media screen and (min-width: ${breakpoint.min.md}) and (max-width: ${breakpoint.max.lg}) {
    grid-column: 2 / span 5 !important;
  }
`;

const ImgElement = styled(motion.img)`
  position: absolute;
  z-index: -1;

  @media (max-width: ${breakpoint.max.lg}) {
    display: none;
  }
`;

const Accent = styled.span`
  color: ${(props) => props.theme.color.sky};
`;

const TestimonialElements = () => {
  return (
    <>
      {ELEMENTS.map((element, index) => (
        <ImgElement
          key={index}
          alt="Devfolio Testimonials"
          src={element.src}
          css={element.css}
          {...(element.spin && spin)}
          {...(element.spinReverse && spinReverse)}
        />
      ))}
    </>
  );
};

const StyledComment = styled.div`
  opacity: 0;
  transform: translateY(24px);
  transition: all 400ms cubic-bezier(0.22, 1, 0.36, 1);
  will-change: opacity, translateY;

  ${(props) =>
    props.animateIn &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};

  @media screen and (min-width: ${breakpoint.min.md}) and (max-width: 1024px) {
    margin-left: 32px;
  }

  @media screen and (min-width: ${breakpoint.min.lg}) and (max-width: 1232px) {
    margin-left: 32px;
  }
`;

const Comment = ({ by, role, children, animateIn }) => {
  return (
    <StyledComment animateIn={animateIn}>
      <H2 color="sand">{children}</H2>

      <div css="margin-top: 16px">
        <P color="grey" css="font-weight: 900">
          {by}
        </P>
        <P color="lightGrey" css="font-weight: 900">
          {role}
        </P>
      </div>
    </StyledComment>
  );
};

const COMMENTS = [
  {
    by: 'Samyak and Sowmay Jain',
    role: 'Co-founders, InstaDApp',
    body: (
      <>
        The fact that our hack <Accent>[InstaDApp]</Accent> saw the light of the day is a great
        signal about the success of the <Accent>Devfolio hackathons</Accent>.
      </>
    ),
  },
  {
    by: 'Chetan Badhe',
    role: 'Co-founder, Incento',
    body: (
      <>
        Magical hacking experience plus fun and enriched networking. The journey to build{' '}
        <Accent>Incento started at one of such amazing hackathon organized by Devfolio.</Accent>
      </>
    ),
  },
  {
    by: 'Devika K Das',
    role: 'Software Engineer, Google',
    body: (
      <>
        InOut connected me to awesome developers & made me feel inclusive.{' '}
        <Accent>Devfolio has been doing an amazing job in keeping the community intact.</Accent>
      </>
    ),
  },
];

const carouselConfig = {
  autoPlay: 5000,
  animationSpeed: 800,
  draggable: false,
  infinite: true,
};

const Testimonials = () => {
  const [index, setActive] = React.useState({ active: 0, activeComment: 0 });

  const controlledProps = {
    onChange: (value) => {
      next(value);
    },
    value: index.active,
  };

  const next = (value) => {
    let nextActiveComment = index.activeComment + 1;
    const nextActive = typeof value === 'number' ? value : index.active + 1;

    if (nextActiveComment >= COMMENTS.length) {
      nextActiveComment = 0;
    }

    setActive({ active: nextActive, activeComment: nextActiveComment });
  };

  const previous = React.useCallback(() => {
    let previousActiveComment = index.activeComment - 1;
    const previousActive = index.active - 1;

    if (previousActiveComment < 0) {
      previousActiveComment = COMMENTS.length - 1;
    }

    setActive({ active: previousActive, activeComment: previousActiveComment });
  }, [index]);

  return (
    <StyledSection grid>
      <Grid defaultOuter>
        <StyledItem
          column={{
            sm: { start: 2, span: 7 },
            md: { start: 2, span: 8 },
            lg: { start: 1, span: 5 },
          }}
        >
          <Carousel {...controlledProps} {...carouselConfig} animationSpeed={0}>
            {COMMENTS.map((comment, commentIndex) => {
              return (
                <Comment
                  key={commentIndex}
                  by={comment.by}
                  role={comment.role}
                  animateIn={commentIndex === index.activeComment}
                >
                  {comment.body}
                </Comment>
              );
            })}
          </Carousel>
        </StyledItem>

        <Item
          column={{
            sm: { start: 2, span: 7 },
            md: { start: 2, span: 8 },
            lg: { start: 7, span: 6 },
          }}
          css="position: relative"
        >
          <Shadow>
            <Carousel {...controlledProps} {...carouselConfig}>
              <img src={oneSrc} alt="1" />
              <img src={twoSrc} alt="2" />
              <img src={threeSrc} alt="3" />
            </Carousel>
          </Shadow>

          <Clickable height="100%" width="100%">
            <Clickable.Area
              arrow="left"
              top="0"
              height="100%"
              left="0"
              width="50%"
              onClick={previous}
            />
            <Clickable.Area
              arrow="right"
              top="0"
              height="100%"
              left="50%"
              width="50%"
              onClick={next}
            />
          </Clickable>

          <TestimonialElements />
        </Item>
      </Grid>
    </StyledSection>
  );
};

export default Testimonials;
