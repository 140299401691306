import React from 'react';
import styled from 'styled-components';

import { H1, P } from '@components/Typography';
import { Section, Container, Button } from '@components/_global';
import Slider from '@components/Slider';
import ExternalLink from '@components/ExternalLink';

import { laptop } from '@constants';
import trackRegistrationsSrc from '@images/features/take-track-registrations.png';

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const PhoneMockup = styled.div`
  display: block;
  margin-bottom: -4px;

  ${laptop} {
    display: none;
  }
`;

const SectionFluid = styled(Section)`
  padding: 5.6rem 2rem 0 !important;

  ${laptop} {
    padding: 5.6rem 0 0 !important;
  }
`;

const ForCommunity = () => {
  return (
    <SectionFluid bgColor="mustard">
      <Container fluid>
        <Flex>
          <div css="margin-bottom: 3rem">
            <H1 css="color: #3b2711">Devfolio for communities</H1>

            <P css="margin-top: 16px; max-width: 720px; margin: 16px auto 0">
              No more creating Google Forms and managing participants in an Excel sheet. You focus
              on your people, leave the rest to us.
            </P>

            <div css="margin-top: 32px; display: flex; justify-content: center">
              <ExternalLink href="https://forms.gle/iQBLZHctje9uFPGQ9">
                <Button>Request platform</Button>
              </ExternalLink>
            </div>

            <P size="small" css="margin-top: 16px; opacity: 0.6">
              *absolutely free for student-run hackathons.
            </P>
          </div>

          <Slider />

          <PhoneMockup>
            <img src={trackRegistrationsSrc} alt="take and track registrations" />
          </PhoneMockup>
        </Flex>
      </Container>
    </SectionFluid>
  );
};

export default React.memo(ForCommunity);
