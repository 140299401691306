import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Icon from '@components/Icon';
import { P, A } from '@components/Typography';

import { phone } from '@constants';
import { storeItem, getItem } from '@helpers';

import cookieSrc from '+static/cookie.svg';

const toastVariants = {
  hidden: {
    scale: 0.9,
    opacity: 1,
    y: 500,
    transition: {
      duration: 0.8,
      ease: [0, 0.55, 0.45, 1],
    },
  },
  visible: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.2,
      duration: 1,
      ease: [0.85, 0, 0.15, 1],
    },
  },
};

const Toast = styled(motion.div)`
  position: fixed;
  z-index: 9999;
  width: 500px;
  right: 24px;
  bottom: 40px;
  display: block;
  padding: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.color.cyan};
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.17);
  box-sizing: border-box;

  ${phone} {
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 10px 10px 0 0;
  }
`;

const Button = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;

  > svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  ${phone} {
    > div:first-child {
      display: none;
    }
  }
`;

const CookieToast = () => {
  const [hasGivenConsent, setGivenConsent] = React.useState(false);
  const [toastStatus, setToastStatus] = React.useState('hidden');

  React.useEffect(() => {
    try {
      if (getItem('devfolio-consent-given')) {
        setGivenConsent(true);
      }
    } catch (error) {
      // Something went wrong with trying to access the devfolio-consent-given
      // localstorage item. In this case, we'll consider that the consent was
      // not provided, and pop up the toast.
    }
  }, []);

  const hideToastAndSetItem = () => {
    try {
      setToastStatus('visible');
      storeItem('devfolio-consent-given', true);
    } catch (error) {
      // The localstorage item could not be set. Continue
      // showing the toast on subsequent visits.
    }
  };

  return (
    !hasGivenConsent && (
      <Toast
        initial={toastStatus}
        animate={toastStatus === 'hidden' ? 'visible' : 'hidden'}
        variants={toastVariants}
      >
        <Flex>
          <div css="flex-basis: 10em; padding-right: 32px">
            <img height="55px" width="55px" src={cookieSrc} alt="Cookie icon" />
          </div>
          <div css="flex-grow: 1">
            <P size="small" color="white">
              Hey! Just letting you know that we use cookies to ensure you have the best experience
              possible on Devfolio. For more information, please read our{' '}
              <A href="/privacy-policy" appearance="link" size="small">
                privacy policy
              </A>
              .
            </P>
          </div>
          <div css="align-self: flex-start">
            <Button onClick={hideToastAndSetItem}>
              <Icon name="x" color="#ffffff"></Icon>
            </Button>
          </div>
        </Flex>
      </Toast>
    )
  );
};

export default React.memo(CookieToast);
