import React from 'react';
import styled from 'styled-components';

import { H1, P } from '@components/Typography';
import { Section, Button } from '@components/_global';
import ExternalLink from '@components/ExternalLink';
import Grid, { Item } from '@components/Grid';
import PlayOnVisible from '@components/PlayOnVisible';

import { breakpoint, phone, tablet } from '@constants';
import useLottie from '@hooks/useLottie';

import connectLottieSrc from '+static/lottie/connect.json';
import skyStarSrc from '+static/elements/sky-star.svg';

const StyledGrid = styled(Grid)`
  ${phone} {
    grid-gap: 0 10px;
  }

  ${tablet} {
    grid-gap: 0 16px;
  }

  @media screen and (max-width: ${breakpoint.max.md}) {
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
`;

const ImgElement = styled.img`
  position: absolute;
  right: 36px;
  bottom: -140px;
`;

const Connect = () => {
  const connectEl = React.useRef(null);
  useLottie(connectEl, connectLottieSrc, { loop: true });

  return (
    <Section id="connect" bgColor="white" grid css="position: relative; overflow: hidden">
      <StyledGrid defaultInner>
        <Item
          column={{
            sm: { start: 2, span: 7 },
            md: { start: 1, span: 10 },
            lg: { start: 1, span: 7 },
          }}
          css="align-self: center; justify-self: center"
        >
          <PlayOnVisible el={connectEl}>
            <div ref={connectEl} />
          </PlayOnVisible>
        </Item>

        <Item
          column={{
            sm: { start: 2, span: 7 },
            md: { start: 2, span: 8 },
            lg: { start: 8, span: 6 },
          }}
        >
          <H1>We speak, we listen, we discuss, we grow.</H1>

          <div css="margin-top: 32px">
            <P>Share ideas, feedback, connect with people over the love of our community.</P>
          </div>

          <div css="margin-top: 24px">
            <ExternalLink href="https://t.me/devfolio">
              <Button>Join our Telegram group</Button>
            </ExternalLink>
          </div>
        </Item>
      </StyledGrid>

      <ImgElement src={skyStarSrc} alt="Sky art icon" />
    </Section>
  );
};

export default React.memo(Connect);
