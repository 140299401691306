import React from 'react';
import styled from 'styled-components';

import { H1 } from '@components/Typography';
import { Section, Underline } from '@components/_global';
import FavoriteDevfolios from '@components/FavoriteDevfolios';
import FavoriteProjects from '@components/FavoriteProjects';
import FeaturesCard from '@components/FeaturesCard';
import Grid, { Item } from '@components/Grid';
import PlayOnVisible from '@components/PlayOnVisible';

import useLottie from '@hooks/useLottie';

import goldbergMachineSrc from '+static/goldberg-machine.svg';

import resumeDigitSrc from '+static/feature-resume-digit.svg';
import resumePointSrc from '+static/feature-resume-point.svg';
import resumeBrowserSrc from '+static/feature-resume-browser.svg';
import resumeLottieSrc from '+static/lottie/resume.json';

import showcaseDigitSrc from '+static/feature-showcase-digit.svg';
import showcasePointSrc from '+static/feature-showcase-point.svg';
import showcaseBrowserSrc from '+static/feature-showcase-browser.svg';
import showcaseLottieSrc from '+static/lottie/showcase.json';

import hackathonDigitSrc from '+static/feature-hackathon-digit.svg';
import hackathonPointSrc from '+static/feature-hackathon-point.svg';
import hackathonBrowserSrc from '+static/feature-hackathon-browser.svg';
import hackathonsLottieSrc from '+static/lottie/hackathons.json';

import skillDigitSrc from '+static/feature-skill-digit.svg';
import skillPointSrc from '+static/feature-skill-point.svg';
import skillBrowserSrc from '+static/feature-skill-browser.svg';
import skillLottieSrc from '+static/lottie/skill.json';

import { phone, breakpoint } from '@constants';
import { routeToHackathons, routeToSignUp, routeToStartQuiz } from '@helpers';

// ____________________________________________________________________
// Styles

const NoPhoneItem = styled(Item)`
  ${phone} {
    display: none;
  }
`;

const StyledArtItem = styled(Item)`
  @media screen and (max-width: ${breakpoint.max.md}) {
    position: absolute;
    max-width: 11rem;

    &[data-art='resume'] {
      right: -50%;
      top: 4%;
    }

    &[data-art='showcase'] {
      left: -56%;
      top: 27%;
    }

    &[data-art='hackathons'] {
      right: -52%;
      top: 51%;
    }

    &[data-art='skill'] {
      left: -60%;
      top: 76%;
    }
  }
`;

const GoldbergMachineImg = styled.img`
  ${phone} {
    display: none;
  }
`;

const ArtItem = ({ placement = 'left', children, ...props }) => {
  return (
    <StyledArtItem
      {...(placement === 'left' && {
        column: {
          sm: { start: 2, span: 7 },
          md: { start: 1, span: 10 },
          lg: { start: 2, span: 4 },
        },
      })}
      {...(placement === 'right' && {
        column: {
          sm: { start: 2, span: 7 },
          md: { start: 1, span: 10 },
          lg: { start: 10, span: 4 },
        },
      })}
      {...props}
    >
      {children}
    </StyledArtItem>
  );
};

const InfoItem = ({ placement = 'left', children }) => {
  return (
    <Item
      css="align-self: center; justify-self: center"
      {...(placement === 'left' && {
        column: {
          sm: { start: 2, span: 7 },
          md: { start: 1, span: 10 },
          lg: { start: 1, span: 8 },
        },
      })}
      {...(placement === 'right' && {
        column: {
          sm: { start: 2, span: 7 },
          md: { start: 1, span: 10 },
          lg: { start: 7, span: 8 },
        },
      })}
    >
      {children}
    </Item>
  );
};

const spanFull = {
  sm: { start: 1, span: 9 },
  md: { start: 1, span: 10 },
  lg: { start: 1, span: 14 },
};

const Features = () => {
  const resumeEl = React.useRef(null);
  const showcaseEl = React.useRef(null);
  const hackathonsEl = React.useRef(null);
  const skillEl = React.useRef(null);

  useLottie(resumeEl, resumeLottieSrc);
  useLottie(showcaseEl, showcaseLottieSrc);
  useLottie(hackathonsEl, hackathonsLottieSrc);
  useLottie(skillEl, skillLottieSrc);

  return (
    <Section id="features" grid css="overflow: hidden">
      <Grid defaultInner css="position: relative">
        <Item
          column={{
            ...spanFull,
            sm: {
              start: 2,
              span: 7,
            },
          }}
        >
          <div css="text-align: center">
            <GoldbergMachineImg src={goldbergMachineSrc} alt="" />

            <H1 css="margin: 4rem 0">
              Devfolio is{' '}
              <Underline color="blue">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Underline>
              .
            </H1>
          </div>
        </Item>
        {/* --------- */}
        {/* 1. Resume */}
        <InfoItem placement="left">
          <FeaturesCard
            digit={resumeDigitSrc}
            icon={<img src={resumePointSrc} alt="" />}
            heading="Your digital up-to-date resumé"
            text="Showcase to the world your skills, links to your social profile, where you have worked and what you have worked on and the projects you have built."
            callToAction="Create your Devfolio"
            action={routeToSignUp}
          />
        </InfoItem>
        <ArtItem placement="right" data-art="resume">
          <PlayOnVisible el={resumeEl}>
            <div ref={resumeEl} css="margin-top: 3rem" />
          </PlayOnVisible>
        </ArtItem>
        <NoPhoneItem column={spanFull}>
          <img src={resumeBrowserSrc} alt="" />
        </NoPhoneItem>
        <NoPhoneItem column={spanFull}>
          <FavoriteDevfolios />
        </NoPhoneItem>
        {/* ------------------- */}
        {/* 2. Showase projects */}
        <ArtItem placement="left" data-art="showcase">
          <PlayOnVisible el={showcaseEl}>
            <div ref={showcaseEl} css="margin-top: 3rem" />
          </PlayOnVisible>
        </ArtItem>
        <InfoItem placement="right">
          <FeaturesCard
            digit={showcaseDigitSrc}
            icon={<img src={showcasePointSrc} alt="" />}
            heading="A showcase of all your projects"
            text="Give your weekend projects, side projects, hobby projects, serious ventures a place to breathe, invite collaborators and inspire other builders."
            callToAction="Add your project"
            action={routeToSignUp}
          />
        </InfoItem>
        <NoPhoneItem column={spanFull}>
          <img src={showcaseBrowserSrc} alt="" />
        </NoPhoneItem>
        <NoPhoneItem column={spanFull}>
          <FavoriteProjects />
        </NoPhoneItem>
        {/* ------------- */}
        {/* 3. Hackathons */}
        <InfoItem placement="left">
          <FeaturesCard
            digit={hackathonDigitSrc}
            icon={<img src={hackathonPointSrc} alt="" />}
            heading="Your portal to the best hackathons"
            text="Applying to hackathons on Devfolio is as simple as a click of a button. We save all the required info so that you don’t have to fill them over and over again."
            callToAction="Browse all hackathons"
            action={routeToHackathons}
          />
        </InfoItem>
        <ArtItem placement="right" data-art="hackathons">
          <PlayOnVisible el={hackathonsEl}>
            <div ref={hackathonsEl} css="margin-top: 2rem" />
          </PlayOnVisible>
        </ArtItem>
        <NoPhoneItem column={spanFull}>
          <img src={hackathonBrowserSrc} alt="" />
        </NoPhoneItem>
        {/* ------------------- */}
        {/* 4. Skill assessment */}
        <ArtItem placement="left" data-art="skill">
          <PlayOnVisible el={skillEl}>
            <div ref={skillEl} />
          </PlayOnVisible>
        </ArtItem>
        <InfoItem placement="right">
          <FeaturesCard
            digit={skillDigitSrc}
            icon={<img src={skillPointSrc} alt="" />}
            heading="Your skill assessment playground"
            text="Take our quiz, earn a badge and see where you stand among the rest of the builders."
            callToAction="Take the quiz"
            action={routeToStartQuiz}
          />
        </InfoItem>
        <NoPhoneItem column={spanFull}>
          <img src={skillBrowserSrc} alt="" />
        </NoPhoneItem>
      </Grid>
    </Section>
  );
};

export default React.memo(Features);
