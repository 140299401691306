import { format, parseISO, isValid, isWithinInterval } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { Button } from '@components/_global';
import { H3, P } from '@components/Typography';

import { ROUTES } from '@helpers';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border-top: 10px solid ${(props) => props.primaryColor};
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px 0px;
  height: 100%;
  padding: 40px;
  will-change: transform, box-shadow;
  box-sizing: border-box;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px;
    transform: translateY(-2px);
    transition: transform 200ms ease-out, box-shadow 200ms ease-out;
  }
`;

const Hackathon = ({
  slug,
  name,
  startsAt,
  regStartsAt,
  regEndsAt,
  city,
  country,
  primaryColor,
  isOnline,
}) => {
  const parsedDate = parseISO(startsAt);
  const isValidDate = isValid(parsedDate);
  const dateString = isValidDate && format(parsedDate, 'MMMM dd, yyyy');

  const location = [city, country];

  const areApplicationsOpen = React.useCallback(() => {
    if (!regEndsAt || !regStartsAt) {
      return false;
    }
    return isWithinInterval(new Date(), { start: parseISO(regStartsAt), end: parseISO(regEndsAt) });
  }, [regStartsAt, regEndsAt]);

  return (
    <Card elevation={3} primaryColor={primaryColor}>
      <div css="word-wrap: break-word">
        <H3>{name}</H3>

        <div css="margin-top: 8px">
          <P size="small" css="font-weight: 600">
            {dateString}
          </P>
        </div>
      </div>

      <div css="margin-top: 64px">
        <P size="small" css="font-weight: 600">
          {isOnline
            ? `Happening Online`
            : `${location.filter((item) => typeof item === 'string').join(', ')}`}
        </P>

        <div css="text-align: -webkit-center; margin-top: 24px">
          {areApplicationsOpen() ? (
            <Button as="a" href={ROUTES.PUBLIC_PAGE(slug)} target="_blank" rel="noreferrer">
              Participate
            </Button>
          ) : (
            <Button as="a" href={ROUTES.SUBMISSIONS(slug)} target="_blank" rel="noreferrer">
              View Submissions
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

Hackathon.defaultProps = {
  name: null,
  date: null,
  location: null,
  primaryColor: '#3770ff',
};

export default Hackathon;
