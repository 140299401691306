export default [
  {
    name: 'Manank Patni',
    username: 'man_jain',
    image: 'manank.jpg',
    borderColor: 'lightTeal',
  },
  {
    name: 'Anshu Jalan',
    username: 'AnshuJalan',
    image: 'anshul.jpeg',
    borderColor: 'sky',
  },
  {
    name: 'Rajat Verma',
    username: 'rajat2502',
    image: 'rajat.jpeg',
    borderColor: 'greenCyan',
  },
  {
    name: 'Mishal Shah',
    username: 'mishal23',
    image: 'mishal.jpg',
    borderColor: 'lightTeal',
  },
  {
    name: 'Mihir Chaturvedi',
    username: 'plibither8',
    image: 'mihir.jpg',
    borderColor: 'greenCyan',
  },
  {
    name: 'Tezan Sahu',
    username: 'tezansahu',
    image: 'tezan.jpg',
    borderColor: 'sky',
  },
  {
    name: 'Somesh Koli',
    username: 'someshkoli',
    image: 'somesh.jpg',
    borderColor: 'lightTeal',
  },
];
